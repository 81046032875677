export const Documents = (props) => {

    return(
        <div id='features' className='text-center' style={{marginTop: 40}}>
      <div className='container'>
        <div className='section-title'>
          <h2>Документы</h2>
        </div>
        <div>
            <a href="https://yes35.ru/images/polozhenie_glk_school.pdf" target="_blank" style={{fontSize: "20px"}}>Положение школы горных лыж и сноуборда "Y.E.S"</a>
        </div>
      </div>
    </div>
    )
}