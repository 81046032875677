export const Ski = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Школа горных лыж и сноуборда</h2>

        </div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/tren_181122.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Яблоков Андрей</h3>
              <p>Главный инструктор Школы горных лыж YES</p>
              <p>Профиль работ: обучение катанию на горных лыжах.</p>
              <p>Взрослые и дети (с 4 лет). Категория: C.</p>
              <p>Мончегорская СШОР по горнолыжному спорту.</p>
              <p>«Федеральный научный центр физической культуры и спорта»(ФГБУ ФНЦ ВНИИФК) по программе : «Теория и методика горнолыжного спорта».</p>
              <p>National Russia (Методика обучения детей технике горнолыжного спорта). НЛИ в соответствии с международными стандартами обучения International Ski Instructors Association (ISIA).</p>
              <p>
                {/* <a href={d.link} className="btn btn-custom btn-lg page-scroll">Записаться</a> */}

                {/* <button className="btn-zayavka"><a href="https://b24-ir4qg1.bitrix24.site/ski_yes35/">Оставить заявку в Школу горных лыж</a></button>           */}
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="https://instructors.yes35.ru/img/instructors/check18.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Тэн Ксения</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 5 лет)</p>
              <p>Вид занятий: индивидуальные занятия.</p>
              <p>Стаж преподавания: с 2023 года.</p>
              <p>Стаж индивидуального катания: с 1998 года.</p>
              <p>
                {/* <a href={d.link} className="btn btn-custom btn-lg page-scroll">Записаться</a> */}

                {/* <button className="btn-zayavka"><a href="https://b24-ir4qg1.bitrix24.site/snowboard_yes35/">Оставить заявку в Школу сноуборда</a></button> */}
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
