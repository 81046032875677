export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className='section-title text-center'>
            <h2>Расписание</h2>
          </div>
          <div style={{ display: "flex", marginBottom: 40 }}>
            <div className="col-md-6" style={{ margin: "0 auto", marginBottom: 40, padding: 20, textAlign: "center" }}>
              <h3>Тренировки проходят в мини-группах 3 раза в неделю:</h3>
              <p> 2 раза в будни в вечернее время после 18.00 часов</p>
              <p> 1 раз в выходной день (суббота или воскресенье).</p>
            </div>
          </div>
          <div className='section-title text-center'>
            <h2>Возрастные группы и этапы обучения</h2>
          </div>

          <div className="age-groups-request">
            <div className="stages-training">
              <div className='col-md-6 group-s'>
                <h3>Младшая группа с 5 лет до 7 лет (включительно)</h3>
                <div className="row">
                  <div className='testimonial'>
                    <div className='testimonial-meta'>Начальный этап</div>
                    <div className='col'>
                      <p>Обучение катанию на горных лыжах при полном отсутствии опыта</p>
                    </div>
                  </div>
                  <div className='testimonial'>
                    <div className='testimonial-meta'>Прогрессирующий этап </div>
                    <div className='col'>
                      <p>Обучение технике катания на горных лыжах для детей, имеющих опыт катания</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-6 group-m'>
                <h3>Старшая группа с 8 лет до 12 лет (включительно)</h3>
                <div className="row">
                  <div className='testimonial'>
                    <div className='testimonial-meta'>Начальный этап</div>
                    <div className='col'>
                      <p>Обучение катанию на горных лыжах при полном отсутствии опыта</p>
                    </div>
                  </div>
                  <div className='testimonial'>
                    <div className='testimonial-meta'>Прогрессирующий этап</div>
                    <div className='col'>
                      <p>Обучение технике катания на горных лыжах для детей, имеющих опыт катания</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div >


  )
}
